import ReactDOM from "react-dom";
import "firebase/firestore";

import "./static/fonts/index.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}